<template>
  <div
    :style="`background-image: url(${sideImg}); background-size: cover;`"
    class="h-100 text-white"
  >
    <b-row
      align-v="center"
      class="h-100 justify-content-center"
    >

      <b-col cols="6">
        <div class="text-center mb-2">
          <h1 class="font-weight-bolder text-white">
            {{ showData.name }}贊助系統
          </h1>
        </div>

        <div class="text-center mb-2">
          <h2 class="font-weight-bolder text-white">
            自助贊助中心, 快速金流繳費, 保障安全隱私
          </h2>
        </div>

        <hr>

        <div class="text-center mb-2">
          <h4 class="font-weight-bolder text-white">
            遊戲伺服器：【{{ showData.name }}】
          </h4>
        </div>

        <b-form-input
          v-model="orderData.account"
          placeholder="請輸入遊戲帳號"
          class="mb-2 form-control"
        />

        <cleave
          v-model="orderData.money"
          :options="{ numeral: true, numeralThousandsGroupStyle: 'no' }"
          placeholder="請輸入繳款金額"
          class="mb-1 form-control"
        />

        <div class="row mb-50">
          <b-form-group
            label="目前幣值"
            class="col-6"
          >
            <b-form-input
              disabled
              placeholder="...等待計算"
              :value="pointRatio.ratio && `1 : ${pointRatio.ratio}`"
            />
          </b-form-group>

          <b-form-group
            label="換算金額"
            class="col-6"
          >
            <b-form-input
              disabled
              placeholder="...等待計算"
              :value="pointRatio.total"
            />
          </b-form-group>
        </div>

        <b-form-select
          v-model="orderData.payment"
          :options="showData.payments || []"
          value-field="id"
          text-field="title"
          class="mb-2 form-control"
        >
          <template #first>
            <b-form-select-option
              disabled
              :value="null"
            >
              請選擇付款方式
            </b-form-select-option>
          </template>
        </b-form-select>

        <div class="text-center">
          <b-overlay
            :show="showLoading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="dark"
              @click="$emit('on-submit', orderData)"
            >
              確定贊助
            </b-button>
          </b-overlay>
        </div>

      </b-col>
    </b-row>

    <slot />
  </div>
</template>

<script>
import {
  BOverlay,
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BOverlay,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,

    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    showLoading: {
      type: Boolean,
      required: true,
    },
    showData: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props) {
    const orderData = ref({
      money: 300,
      // pt: 'ecpay_cvs',
      payment: 1,
    })
    /* eslint-disable global-require */
    const sideImg = computed(() => props.showData.bg_image || require('@/assets/images/background/particles_bg.jpg'))

    const pointRatio = computed(() => {
      const { currencies } = props.showData
      const { money } = orderData.value

      if (currencies && money) {
        const currencie = currencies.find(f => parseInt(money, 0) >= f.min_amount && parseInt(money, 0) <= f.max_amount)
        const ratio = parseFloat((currencie && currencie.ratio) || 1)
        const total = Math.round(parseInt(money, 0) * ratio)
        return { ratio, total }
      }
      return { ratio: null, total: null }
    })

    return {
      orderData,

      sideImg,
      pointRatio,
    }
  },
}
</script>

<style lang="scss">
.form-control {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:disabled {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
  }
}

label {
  color: #d0d2d6;
}

.custom-select {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
